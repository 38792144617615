












































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import InTakeForm from "@/data/mock/InTakeForm";
import DynamicForm from "@/components/DynamicForm.vue";

import { Project } from "@/data/models/Projects";
import DataProvider from "@/lib/DataProvider";
import { User } from "@/data/models/Users";
import { IntakeFormSubmission } from "@/data/models/IntakeFormSubmission";
import Loader from "@/components/Loader.vue";
import FileInput from "@/components/FileInput.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import {
  NewQuestionnaireSubmission,
  Prescreening,
  Questionnaire
} from "@/data/models/Questionnaires";
import ICrudClient from "@/lib/ICrudClient";
import { Guid } from "guid-typescript";
import openPdf from "@/lib/openPdf";
import { toArray } from "@/lib/answers";
import HouseholdMemberBasicData from "@/components/forms/HouseholdMemberBasicData.vue";

import { saveAs } from "file-saver";
@Component({
  components: {
    DynamicForm,
    Loader,
    FileInput,
    EntitySelect,
    HouseholdMemberBasicData
  }
})
export default class IntakeForm extends Vue {
  @Prop()
  public crud!: ICrudClient<IntakeFormSubmission>;

  @Prop()
  public prescreeningId!: string;

  @Prop()
  public projectId!: string;

  @Prop()
  public beneficiaryId!: string;

  @Prop()
  public projectsProvider!: DataProvider<Project>;
  @Prop()
  public usersProvider!: DataProvider<User>;
  @Prop()
  public questionnaireProvider!: DataProvider<Questionnaire>;
  @Prop()
  public prescreeningProvider!: DataProvider<Prescreening>;
  private users: any[] = [];
  private user: any = {};
  private consentAnswers: any[] = [];

  validateForm() {
    const form = this.$refs.outerForm as any;
    return form.validate(true);
  }

  private consentFile = null;
  private intakeFormSubmission: any = {
    consent: null,
    householdMember: {
      householdMemberRoleId: null,
      householdMemberRole: null,
      dateOfBirth: null,
      insuranceOrPermitDate: null,
      isDirectBeneficiary: true,
      additionalInformation: null,
      nationality: null,
      nationalityId: null,
      gender: null,
      genderId: null,
      legalStatus: null,
      legalStatusId: null,
      legalFiles: [],
      housingType: null,
      housingTypeId: null,
      household: {
        childrenBornInGreece: false
        //householdMembers: []
      }
    }
  };
  private prescreeningSubmission: any = null;
  private inTakeForm: any = null;
  private selectedProject: any = null;
  private inTakeAnswers: any[] = [];
  private consentQuestionnaire: any = null;
  private submitting = false;

  @Watch("selectedProject", { immediate: true })
  async onQuestChanged(val: any) {
    if (val && val.id) {
      if (
        this.selectedProject.consentForm &&
        this.selectedProject.consentForm.questionnaireId
      ) {
        this.intakeFormSubmission.consent = {
          file: null
        };
        this.consentQuestionnaire = await this.$service.providers.questionnaires.fetchItemAsync(
          this.selectedProject.consentForm.questionnaireId
        );
      } else {
        this.consentQuestionnaire = null;
        this.intakeFormSubmission.consent = null;
      }
      if (val.intakeFormId) {
        this.intakeFormSubmission.projectId = val.id;

        const res = await this.questionnaireProvider.fetchItemAsync(
          val.intakeFormId
        );
        if (res) {
          this.inTakeForm = res;
        }
      }
    }
  }

  async initialize() {
    this.users = (await this.usersProvider.fetchItemsAsync()).items;
    this.user = this.users[0];
    const res = await this.prescreeningProvider.fetchItemAsync(
      this.prescreeningId
    );

    this.prescreeningSubmission = res;

    if (this.projectId) {
      this.selectedProject = await this.$service.providers.projects.fetchItemAsync(
        this.projectId
      );
    }
  }

  async printConsent(item: any) {
    saveAs(await this.$service.downloadFile(item.name), item.name);
  }
  async submit() {
    this.submitting = true;
    const submission = JSON.parse(JSON.stringify(this.intakeFormSubmission));

    if (this.selectedProject && this.selectedProject.intakeFormId) {
      submission.questionnaireSubmission = NewQuestionnaireSubmission(
        this.selectedProject.intakeFormId,
        this.inTakeAnswers || []
      );
    }
    for (const ans of submission.questionnaireSubmission.answers) {
      ans.questionnaireSubmissionId = submission.questionnaireSubmission.id;
    }
    if (
      submission.householdMember.additionalInformation &&
      submission.householdMember.additionalInformation.questionnaireId
    ) {
      submission.householdMember.additionalInformation = NewQuestionnaireSubmission(
        submission.householdMember.additionalInformation.questionnaireId,
        submission.householdMember.additionalInformation.answers || []
      );
    }
    if (
      this.selectedProject.consentForm &&
      this.selectedProject.consentForm.questionnaireId
    ) {
      submission.consent.submission = NewQuestionnaireSubmission(
        this.selectedProject.consentForm.questionnaireId,
        this.consentAnswers || []
      );
    }

    submission.id = Guid.create().toString();
    if (!this.beneficiaryId) {
      submission.householdMember.id = Guid.create().toString();
      submission.householdMember.householdId = Guid.create().toString();
      submission.householdMember.prescreeningId = this.prescreeningSubmission.id;
      submission.householdMember.householdMemberForLegalFilesId = Guid.create().toString();
    } else {
      delete submission.householdMember.household;
      delete submission.householdMember;
      submission.householdMemberId = this.beneficiaryId;
    }
    if (submission.consent) {
      submission.consent.id = Guid.create().toString();
    }
    const intake: IntakeFormSubmission = submission;
    try {
      const res = await this.crud.addAsync([intake]);
      if (res && res.length > 0) {
        this.$router.push({
          name: "CaseManagement",
          params: {
            id: submission.householdMember
              ? submission.householdMember.id
              : submission.householdMemberId
          }
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.submitting = false;
    }
  }
}
